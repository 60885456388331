@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #262626;
  --toastify-color-info: #D22326;
  --toastify-color-progress-dark: #D22326;
  --toastify-toast-bd-radius: 4px;
  --toastify-font-family: "Roboto Mono", monospace;
  --toastify-toast-padding: 10px 30px 10px 20px;
  --toastify-toast-min-height: 50px;
  --toastify-toast-width: fit-content;
}

.roboto-mono {
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-style: normal;
}
.open-sans {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.oswald {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  color: white;
  background: black;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.lds-facebook,
.lds-facebook div {
  box-sizing: border-box;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #d22326;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0s;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.glass {
  background: rgba(34, 34, 34, 0.6);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border: 1px solid rgba(34, 34, 34, 0.3);
}
.glass-2 {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.13);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b8b8b8;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d22326;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a31b1d;
}

.generic-shadow:hover {
  box-shadow: 0px 0px 10px rgba(234, 234, 234, 0.4);
}
.banana-shadow:hover {
  box-shadow: 0px 0px 20px rgba(232, 223, 75, 0.8);
}
.dork-shadow:hover {
  box-shadow: 0px 0px 20px rgba(171, 105, 235, 0.8);
}
.alpaca-shadow:hover {
  box-shadow: 0px 0px 20px rgba(114, 231, 152, 0.8);
}
.rollbit-shadow:hover {
  box-shadow: 0px 0px 20px rgba(255, 255, 99, 0.8);
}

.woof-shadow:hover {
  box-shadow: 0px 0px 20px rgba(255, 87, 27, 0.8);
}
.dex-shadow:hover {
  box-shadow: 0px 0px 20px rgba(81, 181, 211, 0.8);
}

.swiper-pagination {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  column-gap: 5px;
}
.swiper-pagination-bullet {
  display: flex;
  width: 10px;
  height: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(177, 117, 232, 0.6);
  border-radius: 100%;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #D45EED;
}
.text-gr-1 {
  background: -webkit-linear-gradient(0deg, #D22326, #771113);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
